import { getAction, getByIdAction, postAction, putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/live/itineraryDetail/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";

const getItineraryDetailPageListApi = (params) => getAction(baseURL + '/pageList', params);

const getItineraryDetailListApi=(params) => getAction(baseURL + '/list', params);

const getItineraryDetailSelectByIdApi = (params) => getByIdAction(baseURL + '/selectById', params);

const addItineraryDetailDataApi = (params) => postAction(baseURL + '/add', params);
const batchItineraryDataList = (params) => postAction(baseURL + '/batchDataList', params);


const editItineraryDetailDataApi = (params) => putAction(baseURL + '/edit', params);

const deleteItineraryDetailByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);

export {
    getItineraryDetailPageListApi,
    getItineraryDetailSelectByIdApi,
    addItineraryDetailDataApi,
    editItineraryDetailDataApi,
    deleteItineraryDetailByIdApi,
    getItineraryDetailListApi,
    batchItineraryDataList
}

